import request from '@/utils/request'
export const loginApi = (data) => request.post('/public/login', data)
export const getUserInfo = () => request.get('/auth/getInfo',{useToken:true})
export const logoutApi = () => request.post('/auth/logout',{useToken:true})
export const getUserApi= (data) => request.get('/public/sysUsers/getUserByUsername',{params:data})
export const updateSysUsersApi = (data) => request.post('/sysUsers/update', data,{useToken:true})
export const getAllUsernameApi  = () => request.get('/public/sysUsers/getAllUsername')
export function changeBackgroundWallApi(data){
  return request({
    url:'/sysUsers/changeBackgroundWall',
    method:'post',
    params:data,
    useToken:true
  })
}
export function getUserInfoByConditionApi(query){
  return request({
    url:'/sysUsers/getUserInfoByCondition',
    method:'get',
    params:query
  })
}
export function registApi(data){
  return request({
    url:'/public/regist',
    method:'post',
    data
  })
}
export function updatePasswordApi(data){
    return request({
        url:'/sysUsers/updatePassword',
        method:'post',
        params:data,
        useToken:true
    })
}
